* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Heebo", sans-serif;
}
.loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader-white {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3.5px solid #000;
  animation: prixClipFix 2s linear infinite ;
}
.loader-white::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3.5px solid #fff;
  animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

/* Change the color of the dots */
.slick-dots li button:before {
  color: white !important; /* Change this to your desired color */
}

/* Change the color of the active dot */
.slick-dots li.slick-active button:before {
  color: rgb(254,185,2) !important; /* Change this to your desired color */
}
.slick-slide { padding: 0 10px !important; }
.slick-list { margin: 0 -10px !important; }
